<template>
  <v-window v-if="error" touchless>
    <v-window-item>
      <transition name="fade">
        <div class="pl-16 pr-16 pb-16 pt-4">
          <div class="text-h2 mb-16">Reset Password</div>
          <v-alert elevation="6" type="error" class="mb-16">
            {{ error }}
          </v-alert>

          <div class="text-center">
            <v-btn
              v-bind="btnActive"
              x-large
              type="button"
              @click="$router.push({ path: '/login' })"
            >
              Return to login page
            </v-btn>
          </div>
        </div>
      </transition>
    </v-window-item>
  </v-window>
  <v-window v-else touchless>
    <v-window-item v-if="local.email">
      <transition name="fade">
        <div class="pl-16 pr-16 pb-16 pt-4">
          <div class="text-h2 mb-16">Reset Password</div>
          <div class="mb-4">
            <h3 class="mb-4">Email</h3>
            <v-text-field
              outlined
              v-model="local.email"
              readonly
            ></v-text-field>
          </div>
          <div class="mb-4">
            Enter the new password as indicated below!
            <div style="font-size: 0.8rem">
              <ul style="list-style-type: square">
                <li>At least 8 characters</li>
                <li>A mixture of both uppercase and lowercase letters</li>
                <li>A mixture of letters and numbers</li>
                <li>Space [&#9141;] character is not allowed</li>
                <li>
                  Inclusion of at least one uppercase, lowercase, number and
                  special character : # ? ! @ $ % ^ & * -
                </li>
              </ul>
            </div>
          </div>
          <div class="mb-4">
            <h3 class="mb-4">New Password</h3>
            <v-text-field
              outlined
              v-model="local.password"
              :type="showPwd1 ? 'text' : 'password'"
              :append-icon="showPwd1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPwd1 = !showPwd1"
              @blur="showErrors.password = true"
              @focus="showErrors.password = false"
              :error-messages="
                showErrors.password && errorMessages.password
                  ? errorMessages.password
                  : null
              "
            ></v-text-field>
          </div>
          <div class="mb-4">
            <h3 class="mb-4">Confirm Password</h3>
            <v-text-field
              outlined
              v-model="local.confirmPassword"
              :type="showPwd2 ? 'text' : 'password'"
              :append-icon="showPwd2 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPwd2 = !showPwd2"
              @blur="showErrors.confirmPassword = true"
              @focus="showErrors.confirmPassword = false"
              :error-messages="
                showErrors.confirmPassword && errorMessages.confirmPassword
                  ? errorMessages.confirmPassword
                  : null
              "
            ></v-text-field>
          </div>
          <v-btn
            v-bind="buttonType"
            @click="submit"
            x-large
            :loading="processing"
          >
            Submit
          </v-btn>
        </div>
      </transition>
    </v-window-item>
  </v-window>
</template>

<script>
import { isEmpty, isEqual } from 'lodash'
export default {
  name: 'ResetPassword',
  props: ['actionCode', 'continueUrl'],
  data: function () {
    return {
      error: null,
      timer: 5,
      local: {
        email: '',
        password: '',
        confirmPassword: ''
      },
      processing: false,
      showPwd1: false,
      showPwd2: false,
      showErrors: {
        email: false,
        password: false,
        confirmPassword: false
      }
    }
  },
  mounted() {
    if (!this.actionCode) {
      this.$router.push({ path: '/login' })
      return
    }

    this.$store.commit('app/SET_DATA_LOADED', false)
    this.$store
      .dispatch('account/auth_verify_password_reset_code', this.actionCode)
      .then((email) => {
        this.local.email = email
      })
      .catch(this.onCodeError)
    this.$store.commit('app/SET_DATA_LOADED', true)
  },
  computed: {
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        password: null,
        confirmPassword: null
      }

      msgs.password = this.validatePassword(this.localFormatted.password)

      if (
        !isEqual(
          this.localFormatted.password,
          this.localFormatted.confirmPassword
        )
      ) {
        msgs.confirmPassword = 'Please confirm your new password'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    localFormatted() {
      return {
        email: this.local.email.trim(),
        password: this.local.password,
        confirmPassword: this.local.confirmPassword
      }
    }
  },
  methods: {
    onCodeError(e) {
      if (e.code && e.code === 'auth/expired-action-code') {
        this.error = 'The action code has expired. Retry again!'
      } else if (e.code && e.code === 'auth/invalid-action-code')
        this.error = 'The action code is invalid!'
      else {
        this.error = 'An error occurred!'
      }
    },
    validatePassword(v) {
      if (!v) return 'Password is required'

      if (v.length < 8) return 'Password must be at least 8 characters long'

      if (/.*[a-z].*/.test(v) === false)
        return 'Password must contain at least 1 lowercase letter'

      if (/.*[A-Z].*/.test(v) === false)
        return 'Password must contain at least 1 uppercase letter'

      if (/.*[0-9].*/.test(v) === false)
        return 'Password must contain at least 1 number'

      if (/.*[$#?!@$%^&*-].*/.test(v) === false)
        return 'Password must contain at least 1 special character : # ? ! @ $ % ^ & * -'

      return null
    },
    async submit() {
      if (this.isValid) {
        this.processing = true

        this.$store
          .dispatch('account/auth_verify_password_reset_code', this.actionCode)
          .then(() => {
            this.$store
              .dispatch('account/auth_confirm_password_reset', {
                actionCode: this.actionCode,
                password: this.localFormatted.password
              })
              .then(() => {
                const t = setInterval(() => {
                  this.timer -= 1
                  if (this.timer <= 0) {
                    this.timer = 0
                    clearInterval(t)
                    this.processing = false
                    if (!isEmpty(this.continueUrl)) {
                      window.location.href = this.continueUrl
                    } else this.$router.push({ path: '/login' })
                  }
                }, 500)
              })
              .catch(this.onCodeError)
          })
      }
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    }
  },
  watch: {
    timer(v) {
      this.$store.commit('app/SET_MESSAGE', {
        text: `Your password has been changed! Redirecting to login page... [${v} sec.]`,
        type: 'success'
      })
    }
  }
}
</script>
 